import React from "react";
import { withRouter } from "react-router";
import OneSignal from 'react-onesignal';
import './Tarot.css';

class Tarot extends React.Component {
  constructor(props) {
    super(props);
    OneSignal.initialize('588a4cd3-e5f7-46ff-ba3b-9b285d0ddf70');

    const { match /*, location, history */} = props;
    this.state = {smsCode: match["params"]["smsCode"]};

    this.state = {
      version: match["params"]["version"] ? match["params"]["version"] : null,
      selectedCards: this._checkHasPreviousResult() ? JSON.parse(localStorage.getItem('selectedCards')) : [], 
      resultSMSCode: null,
      cards: [
        {number: 0, id: 0},
        {number: 1, id: 1},
        {number: 2, id: 2},
        {number: 3, id: 3},
        {number: 4, id: 4},
        {number: 5, id: 5},
        {number: 6, id: 6},
        {number: 7, id: 7},
        {number: 8, id: 8},
        {number: 9, id: 9},
        {number: 10, id: 10},
        {number: 11, id: 11}
      ]
    };
    
    this.selectCard = this.selectCard.bind(this);
    this.shuffleCards = this.shuffleCards.bind(this);

    this.rootRef = null;
    this.titleRef = null;
    this.titleResultRef = null;
    this.cardsWrapperRef = null;
    this.analizaWrapperRef = null;
    this.cardRefs = {};
    this.vidovnjakinjaRef = null;

    this.resultSubTitleRef = null;
    this.resultDescRef = null;
    this.resultQuestionRef = null;
    this.resultAnswerRef = null;
    this.resultSMSRef = null;
    this.resultSMSMobileRef = null;
  }
  _checkHasPreviousResult() {
    var selectedCards = localStorage.getItem('selectedCards');
    var selectedCardsExpire = localStorage.getItem('selectedCardsExpire');
    if(selectedCards != null && selectedCardsExpire != null) {
      selectedCardsExpire = new Date(JSON.parse(selectedCardsExpire));
      if(selectedCardsExpire > new Date( Date.now() - 10 * 60000 )) {
        return true;
      }
    }
  }
  componentDidMount() {
    var vidovnjakinjaShow = localStorage.getItem('vidovnjakinjaShow');
    if(!vidovnjakinjaShow) {
      this.vidovnjakinjaRef.style.display = 'none';
      localStorage.setItem('vidovnjakinjaShow', true);
    }
    if(this.state.selectedCards.length > 0) {
      this.rootRef.classList.add('no-anim');
      let cardPosition = 1;
      for(const selectedCardIndex of this.state.selectedCards) {
        this._adjustSelectedCard(cardPosition, this.cardRefs[selectedCardIndex]);
        cardPosition++;
      }
      var selectedCardsResult = localStorage.getItem('selectedCardsResult');
      this.displayResults(JSON.parse(selectedCardsResult), true);
    }
  }
  render() {
    return (
      <div ref={(instance)=>{this.rootRef = instance;}} className="wrapper">
        <div class="header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-header">
                        <a class="navbar-brand" href="/"><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>
                    </div>
                </div>
            </nav>
        </div>
        <div class="main-content">
            <div class="container">
                <div className="title-wrapper">
                  <div className="title" ref={(instance)=>{this.titleRef = instance;}}>
                    <h1>BESPLATNO ČITANJE TAROTA</h1>
                    <h4>Saznajte o svojoj ljubavi, vezama, financijama ili karijeri </h4>
                    <div class="space"></div>
                    <h5>Odaberite 3 karte iz špila ispod</h5>
                    <div class="space"></div>
                  </div>
                  <div className="title-result" ref={(instance)=>{this.titleResultRef = instance;}}>
                    <h2>Što Vam karte poručuju</h2>
                  </div>
                </div>
                <div className="cards-wrapper" ref={(instance)=>{this.cardsWrapperRef = instance;}}>
                  <div className="cards-row">
                      {this.state.cards.map((card, index) => {
                        return (
                          <div id={card.id} style={{zIndex: card.number, top: "5.4%", left: this._getCardLeft(card.number)}} alt="" ref={(instance)=>{this.cardRefs[index] = instance;}} onClick={this.selectCard} index={card.number} className="card">
                            <div className="card-title"></div>
                            <div className="card-inner">
                              <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                              <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="ghost-cards hide-on-mobile">
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-1" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-2" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-3" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-4" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-5" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-6" />
                  </div>
                </div>
                
                <div className="hide-on-reveal">
                  <a href="#" onClick={this.shuffleCards} class="btn btn-tarot">PROMJEŠAJTE KARTE</a>
                  <h6>Prije nego što odaberete svoje karte, razmislite o pitanju na koje želite jasan odgovor.<br/> Tada možete odabrati 3 karte iz tarot špila i dobiti besplatno čitanje tarota!</h6>
                </div>
                
                <div className="analiza-wrapper" ref={(instance)=>{this.analizaWrapperRef = instance;}}>
                  <div class="container">
                    <div className="col-sm-8 col-sm-offset-2">
                      <h6 style={{fontSize: "18px"}} ref={(instance)=>{this.resultSubTitleRef = instance;}}></h6>
                      <p ref={(instance)=>{this.resultDescRef = instance;}}></p>
                      <p><span class="gradiant-txt" ref={(instance)=>{this.resultQuestionRef = instance;}}></span></p>
                      <p style={{fontWeight: "bold"}} ref={(instance)=>{this.resultAnswerRef = instance;}}></p>
                      
                      <div class="transparent-box hide-on-mobile">
                          <div class="row">
                              <div class="col-sm-12">
                                  <p>Za detaljno tumačenje vaših karata</p>
                                  <p>Pošaljite osobni <span class="gradiant-txt">SMS KOD:</span></p>
                              </div>
                              <div class="col-sm-8 col-sm-offset-2">
                                  <a ref={(instance)=>{this.resultSMSRef = instance;}} style={{display: "block", textDecoration: "none", outline: "none", userSelect: "none", color: "white", margin: 'auto'}}>
                                    <div class="button-animation">
                                        <h2>{this.state.resultSMSCode} </h2>na 862862
                                    </div>
                                  </a>
                              </div>
                              <div class="col-sm-12 code-info">
                                  <p class="grey-txt">Pošaljite SMS-om vaš osobni kod {this.state.resultSMSCode} na 862862. Saznajte odgovore u tarot kartama koje ste otvorili. Ovo je pretplatnička usluga. 9 kn/SMS max 2 x tjedno. Zaigrajte kvizove iznenađenja i otkrijte što vam donosi budućnost!</p>
                              </div>
                          </div>
                      </div>

                      <div class="hide-on-desktop">
                        <div class="space"></div>
                        <p>Za detaljno tumačenje karata klikni tu:</p>
                        <a ref={(instance)=>{this.resultSMSMobileRef = instance;}} style={{display: "block", textDecoration: "none", outline: "none", userSelect: "none", color: "white", maxWidth: "280px", margin: 'auto'}}>
                          <div class="button-animation" style={{padding: "8px 0"}}>
                              <h5>PREUZMI DETALJNO TUMAČENJE</h5>
                          </div>
                        </a>
                        <p style={{fontSize: "13px", lineHeight: 1, marginTop: "-20px", color: "#c1d9ef", opacity: 0.5}}>Pošaljite SMS-om vaš osobni kod {this.state.resultSMSCode} na 862862. Saznajte odgovore u tarot kartama koje ste otvorili. Ovo je pretplatnička usluga. 9 kn/SMS max 2 x tjedno. Zaigrajte kvizove iznenađenja i otkrijte što vam donosi budućnost!</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="container" style={{display: "flex", flexDirection: "column", flex: 1, justifyContent: "flex-end"}}>
              <div className="col-sm-8 col-sm-offset-2">
                <div className="col-sm-12 note">
                  <div>
                    <p><small>Usluga je namjenjena samo za zabavu. Služba za korisnike: 09:00 - 17:00 sati na 042500871 ili na comparo.hr@ccsupport.biz. Organizator usluge je: Comparo Media d.o.o., Tometići 15a, 51215, Kastav, OIB 80948414608. Tehnička služba: NTH Mobile d.o.o. Međimurska 28, 42 000 Varaždin, MB: 070096612, OIB 12385860076. Servis je dostupan samo korisnicima unutar Hrvatske, 18+.</small></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="vidovnjakinja hide-on-reveal" ref={(instance)=>{this.vidovnjakinjaRef = instance;}}>
            <div class="container">
                <div class="col-sm-6 col-sm-offset-3">
                    <h1>Nazovite Astro24 Tarot Centar</h1>
                    <h6>Razgovarajte sa savjetnikom koji će vam pomoći da otključate jasnoću usred nesigurnosti</h6>
                    <div class="col-sm-8 col-sm-offset-2">
                        <div class="jaspos">
                            <div class="row">
                                <div class="col-sm-12">
                                    <img src="img/jasmina.jpg" class="img-responsive"/>
                                </div>
                            </div>
                            <div class="row hide-on-mobile" style={{marginTop: "10px"}}>
                                <div class="col-sm-3">
                                    <center><i class="fas fa-phone-alt"></i></center>
                                </div>
                                <div class="col-sm-9">
                                    <h6>NAZOVITE NA BROJ:</h6>
                                    <h3>064 501 503</h3>
                                    <small>FIX: 3,49 kn /min  MOB: 4,78 kn</small><p></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8 col-sm-offset-2 hide-on-desktop" style={{marginTop: "20px"}}>
                          <a href="tel:064 501 503" style={{display: "block", textDecoration: "none", outline: "none", userSelect: "none", color: "white", margin: 'auto'}}>
                            <div class="button-animation" style={{padding: "3px 0", margin: "0", marginBottom: "5px"}}>
                                <h5 style={{textAlign: "initial", margin: "0"}}><i class="fas fa-phone-alt" style={{borderColor: "white"}}></i> <span style={{marginLeft: "10px", fontSize: "25px"}}>064 501 503</span></h5>
                            </div>
                          </a>
                          <span style={{float: "right", color: "#5073b0"}}>4.78kn/min</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


      </div>);
  }

  _getCardLeft(index) {
    var isMobile = window.matchMedia('(max-width:1023px)').matches;
    if(isMobile) {
      return `calc(100vw / 12 * ${index + 1} - (4% * ${index + 1}))`;
    }
    switch(index) {
      case 0: return "19%";
      case 1: return "23.7%";
      case 2: return "28.4%";
      case 3: return "33.1%";
      case 4: return "37.8%";
      case 5: return "42.5%";
      case 6: return "47.2%";
      case 7: return "51.9%";
      case 8: return "56.6%";
      case 9: return "61.3%";
      case 10: return "66%";
      case 11: return "70.7%";
    }
  }

  shuffleCards() {
    let count = 0;
    let startShuffle = ()=>{
      this._shuffle();
      if(count < 6) {
        setTimeout(startShuffle, 300);
        count++;
      }
    }
    startShuffle();
  }

  _shuffle(){
    let newOrder = this.state.cards.map((card) => card.number).sort((a,b)=>Math.random()>0.5? 1: -1)
    var cards = this.state.cards.map((card, index) => {
      card.number = newOrder[index];
      return card;
    });
    this.setState({cards: cards});
  }

  async selectCard(e) {
    const cardToSelect = e.currentTarget;

    if(cardToSelect.classList.contains("card-selected")) return;

    const selectedCards = this.state.selectedCards;
    selectedCards.push(parseInt(cardToSelect.getAttribute("id")));
    this.setState({selectedCards: selectedCards});

    this._adjustSelectedCard(selectedCards.length, cardToSelect);
    
    if(this.state.selectedCards.length === 3) {
      this.cardsWrapperRef.classList.add("cards-reveal");

      const res = await fetch('https://astro24.net/api/api.php?action=getTarotResult&cards=' + this.state.selectedCards.join(','));
      const response = await res.json();

      const result = response["result"];
      this.displayResults(result);
    }
  }

  _adjustSelectedCard(cardPosition, cardToSelect) {
    cardToSelect.classList.add('card-selected');
    cardToSelect.classList.add('card-selected-' + cardPosition);
    cardToSelect.style["transition"] = "all 0.3s ease-in-out";
  }

  async displayResults(result, resultIsPreExistent) {
    Object.entries(result["cards"]).forEach((card, index) => {
      const cardSelected = this.cardsWrapperRef.querySelector(`[id="${this.state.selectedCards[index]}"]`)
      const cardBackImgEl = cardSelected.querySelector(".card-flip-back img");
      const cardTitleEl = cardSelected.querySelector(".card-title");

      if(card["1"]["direction"] === "backward") {
        cardBackImgEl.parentNode.classList.add('backward');
      }

      cardTitleEl.innerHTML = card["1"]["name"];
      cardBackImgEl.setAttribute("src", card["1"]["src"]);
    }, this);
    this.resultSubTitleRef.innerHTML= result["subtitle"];
    this.resultDescRef.innerHTML= result["desc"];
    this.resultQuestionRef.innerHTML= result["question"];
    this.resultAnswerRef.innerHTML= result["answer"];
    
    this.resultSMSRef.setAttribute("href", "sms:862862?&body=" + result["sms"]);
    this.resultSMSMobileRef.setAttribute("href", "sms:862862?&body=" + result["sms"]);
    this.setState({resultSMSCode : result["sms"]});

    this.titleRef.style["opacity"] = 0;
    this.titleResultRef.style["opacity"] = 1;
    this.rootRef.classList.add("root-cards-revealed");
    this.cardsWrapperRef.classList.add("cards-revealed");

    var ro = new ResizeObserver(entries => {
      const isMobile = window.matchMedia('(max-width:1023px)').matches; if(!isMobile) return;
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.cardsWrapperRef.style.height = `${cr.height}px`;
      }
    });
    ro.observe(this.analizaWrapperRef);

    // const countdownIntervalId = setInterval(this._decrementResultCountdown.bind(this), 1000);
    // this.setState({resultCountdownIntervalId : countdownIntervalId});

    if(!resultIsPreExistent) {
      localStorage.setItem('selectedCards', JSON.stringify(this.state.selectedCards));
      localStorage.setItem('selectedCardsResult', JSON.stringify(result));
      localStorage.setItem('selectedCardsExpire', JSON.stringify(Date.now()));
    }
  }
}
export default withRouter(Tarot);